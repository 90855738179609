/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ListCountiesDbResponseDataModelApiModel } from '../../models/list-counties-db-response-data-model-api-model';

export interface GetByState$Params {
  stateId: string;
}

export function getByState(http: HttpClient, rootUrl: string, params: GetByState$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ListCountiesDbResponseDataModelApiModel>>> {
  const rb = new RequestBuilder(rootUrl, getByState.PATH, 'get');
  if (params) {
    rb.path('stateId', params.stateId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ListCountiesDbResponseDataModelApiModel>>;
    })
  );
}

getByState.PATH = '/v1/counties/list/{stateId}';
