/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BasicPopulationDataModelApiModel } from '../models/basic-population-data-model-api-model';
import { CountyLookupDbResponseDataModelApiModel } from '../models/county-lookup-db-response-data-model-api-model';
import { GeoJsonCountyDataModelApiModel } from '../models/geo-json-county-data-model-api-model';
import { getAllBoundries } from '../fn/counties/get-all-boundries';
import { GetAllBoundries$Params } from '../fn/counties/get-all-boundries';
import { getBoundriesByState } from '../fn/counties/get-boundries-by-state';
import { GetBoundriesByState$Params } from '../fn/counties/get-boundries-by-state';
import { getByState } from '../fn/counties/get-by-state';
import { GetByState$Params } from '../fn/counties/get-by-state';
import { getByStates } from '../fn/counties/get-by-states';
import { GetByStates$Params } from '../fn/counties/get-by-states';
import { getByZipcode } from '../fn/counties/get-by-zipcode';
import { GetByZipcode$Params } from '../fn/counties/get-by-zipcode';
import { getPopulationByStateAndCounty } from '../fn/counties/get-population-by-state-and-county';
import { GetPopulationByStateAndCounty$Params } from '../fn/counties/get-population-by-state-and-county';
import { ListCountiesDbResponseDataModelApiModel } from '../models/list-counties-db-response-data-model-api-model';
import { multiStateCountyPopulation } from '../fn/counties/multi-state-county-population';
import { MultiStateCountyPopulation$Params } from '../fn/counties/multi-state-county-population';
import { PopulationLookupDataModelApiModel } from '../models/population-lookup-data-model-api-model';
import { search } from '../fn/counties/search';
import { Search$Params } from '../fn/counties/search';

@Injectable({ providedIn: 'root' })
export class ItkGeoCountiesApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `countiesControllerGetAllBoundries()` */
  static readonly CountiesControllerGetAllBoundriesPath = '/v1/counties/boundries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBoundries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBoundries$Response(params?: GetAllBoundries$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GeoJsonCountyDataModelApiModel>>> {
    return getAllBoundries(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllBoundries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBoundries(params?: GetAllBoundries$Params, context?: HttpContext): Observable<Array<GeoJsonCountyDataModelApiModel>> {
    return this.getAllBoundries$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GeoJsonCountyDataModelApiModel>>): Array<GeoJsonCountyDataModelApiModel> => r.body)
    );
  }

  /** Path part for operation `countiesControllerGetBoundriesByState()` */
  static readonly CountiesControllerGetBoundriesByStatePath = '/v1/counties/boundries/{stateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBoundriesByState()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoundriesByState$Response(params: GetBoundriesByState$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<GeoJsonCountyDataModelApiModel>>> {
    return getBoundriesByState(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBoundriesByState$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBoundriesByState(params: GetBoundriesByState$Params, context?: HttpContext): Observable<Array<GeoJsonCountyDataModelApiModel>> {
    return this.getBoundriesByState$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GeoJsonCountyDataModelApiModel>>): Array<GeoJsonCountyDataModelApiModel> => r.body)
    );
  }

  /** Path part for operation `countiesControllerGetPopulationByStateAndCounty()` */
  static readonly CountiesControllerGetPopulationByStateAndCountyPath = '/v1/counties/population';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPopulationByStateAndCounty()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPopulationByStateAndCounty$Response(params: GetPopulationByStateAndCounty$Params, context?: HttpContext): Observable<StrictHttpResponse<PopulationLookupDataModelApiModel>> {
    return getPopulationByStateAndCounty(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPopulationByStateAndCounty$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getPopulationByStateAndCounty(params: GetPopulationByStateAndCounty$Params, context?: HttpContext): Observable<PopulationLookupDataModelApiModel> {
    return this.getPopulationByStateAndCounty$Response(params, context).pipe(
      map((r: StrictHttpResponse<PopulationLookupDataModelApiModel>): PopulationLookupDataModelApiModel => r.body)
    );
  }

  /** Path part for operation `countiesControllerMultiStateCountyPopulation()` */
  static readonly CountiesControllerMultiStateCountyPopulationPath = '/v1/counties/multi-state-population';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiStateCountyPopulation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  multiStateCountyPopulation$Response(params: MultiStateCountyPopulation$Params, context?: HttpContext): Observable<StrictHttpResponse<BasicPopulationDataModelApiModel>> {
    return multiStateCountyPopulation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiStateCountyPopulation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  multiStateCountyPopulation(params: MultiStateCountyPopulation$Params, context?: HttpContext): Observable<BasicPopulationDataModelApiModel> {
    return this.multiStateCountyPopulation$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicPopulationDataModelApiModel>): BasicPopulationDataModelApiModel => r.body)
    );
  }

  /** Path part for operation `countiesControllerGetByZipcode()` */
  static readonly CountiesControllerGetByZipcodePath = '/v1/counties/zips/{zipcode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByZipcode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByZipcode$Response(params: GetByZipcode$Params, context?: HttpContext): Observable<StrictHttpResponse<CountyLookupDbResponseDataModelApiModel>> {
    return getByZipcode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getByZipcode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByZipcode(params: GetByZipcode$Params, context?: HttpContext): Observable<CountyLookupDbResponseDataModelApiModel> {
    return this.getByZipcode$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountyLookupDbResponseDataModelApiModel>): CountyLookupDbResponseDataModelApiModel => r.body)
    );
  }

  /** Path part for operation `countiesControllerGetByState()` */
  static readonly CountiesControllerGetByStatePath = '/v1/counties/list/{stateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByState()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByState$Response(params: GetByState$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ListCountiesDbResponseDataModelApiModel>>> {
    return getByState(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getByState$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getByState(params: GetByState$Params, context?: HttpContext): Observable<Array<ListCountiesDbResponseDataModelApiModel>> {
    return this.getByState$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ListCountiesDbResponseDataModelApiModel>>): Array<ListCountiesDbResponseDataModelApiModel> => r.body)
    );
  }

  /** Path part for operation `countiesControllerGetByStates()` */
  static readonly CountiesControllerGetByStatesPath = '/v1/counties/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getByStates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getByStates$Response(params: GetByStates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ListCountiesDbResponseDataModelApiModel>>> {
    return getByStates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getByStates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getByStates(params: GetByStates$Params, context?: HttpContext): Observable<Array<ListCountiesDbResponseDataModelApiModel>> {
    return this.getByStates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ListCountiesDbResponseDataModelApiModel>>): Array<ListCountiesDbResponseDataModelApiModel> => r.body)
    );
  }

  /** Path part for operation `countiesControllerSearch()` */
  static readonly CountiesControllerSearchPath = '/v1/counties/{search}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method doesn't expect any request body.
   */
  search$Response(params: Search$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CountyLookupDbResponseDataModelApiModel>>> {
    return search(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  search(params: Search$Params, context?: HttpContext): Observable<Array<CountyLookupDbResponseDataModelApiModel>> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountyLookupDbResponseDataModelApiModel>>): Array<CountyLookupDbResponseDataModelApiModel> => r.body)
    );
  }

}
